import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import format from 'date-fns/format'
import useMedia from 'use-media'
import * as EmailValidator from 'email-validator'
import { useSpring, useTransition } from 'react-spring'
import { Progress, Container, CloseButton, StepButton } from '../FullScreenForm'
import Portal from '../Portal'
import NoWorkshop from './NoWorkshop'
import WorkshopDetails from './WorkshopDetails'
import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import FinalStep from './FinalStep'

const WorkshopApplication = ({ onClose, workshopDetails }) => {
  const [toggle, setToggle] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [policy1, setPolicy1] = useState('')
  const [policy2, setPolicy2] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorAnimation, toggleErrorAnimation] = useState(false)
  const [content, setContent] = useState()

  const isMedium = useMedia({ maxWidth: 1100 })
  const [currentStep, setCurrentStep] = useState(null)

  useEffect(() => setToggle(true), [])
  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }

  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const { wiggle } = useSpring({
    from: { x: 0 },
    wiggle: errorAnimation ? 1 : 0,
    config: { duration: 350 },
  })

  const handleStepBack = useCallback(() => {
    setCurrentStep(prev => prev - 1)
  }, [])

  const handleStepForward = useCallback(() => {
    if (
      (currentStep === 0 && name.length > 0) ||
      (currentStep === 1 && EmailValidator.validate(email) > 0) ||
      (currentStep === 2 && telephone.length > 0) ||
      (currentStep === 3 && policy1) ||
      (currentStep === 4 && policy2)
    ) {
      setCurrentStep(prev => prev + 1)
      setIsError(false)
    } else {
      toggleErrorAnimation(prev => !prev)
      setIsError(true)
    }
  }, [currentStep, name, email, telephone, policy1, policy2])

  const setIndex = () => setCurrentStep(null)

  useEffect(() => {
    const enterPress = e => {
      if (e.keyCode === 13) handleStepForward()
    }
    window.addEventListener('keydown', enterPress)
    return () => window.removeEventListener('keydown', enterPress)
  }, [handleStepForward])

  useEffect(() => {
    const handleSubmit = () => {
      axios({
        method: 'post',
        url: '/.netlify/functions/workshop',
        data: {
          name,
          email,
          telephone,
          emailConsent: policy2,
          workshopDetails,
          date: format(
            new Date(workshopDetails.date),
            `dd.MM.yyyy 'u' k.mm 'sati'`
          ),
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(res => setIsError(false))
        .catch(err => setIsError(true))
      setCurrentStep(5)
    }

    if (currentStep === 0)
      setContent(<Step0 value={name} setValue={setName} wiggle={wiggle} />)
    if (currentStep === 1)
      setContent(<Step1 value={email} setValue={setEmail} wiggle={wiggle} />)
    if (currentStep === 2)
      setContent(
        <Step2 value={telephone} setValue={setTelephone} wiggle={wiggle} />
      )
    if (currentStep === 3)
      setContent(
        <Step3 value={policy1} setValue={setPolicy1} wiggle={wiggle} />
      )
    if (currentStep === 4)
      setContent(
        <Step4
          value={policy2}
          setValue={setPolicy2}
          onSubmit={handleSubmit}
          wiggle={wiggle}
        />
      )
  }, [
    currentStep,
    email,
    name,
    policy1,
    policy2,
    telephone,
    wiggle,
    workshopDetails,
    workshopDetails.address,
    workshopDetails.date,
  ])

  if (currentStep === 5)
    return (
      <Portal>
        {background.map(
          ({ item, key, props }) =>
            item && (
              <Container key={key} style={props}>
                <FinalStep onClose={handleClose} isError={isError} />
                <CloseButton
                  onClick={handleClose}
                  width={isMedium ? 20 : 40}
                  strokeWidth={isMedium ? 4 : 2}
                />
              </Container>
            )
        )}
      </Portal>
    )
  if (new Date(workshopDetails.date) < new Date())
    return <NoWorkshop onClose={handleClose} />
  if (currentStep === null)
    return (
      <WorkshopDetails
        workshopDetails={workshopDetails}
        setCurrentStep={setCurrentStep}
        onClose={handleClose}
      />
    )
  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              {content}
              <Progress currentStep={currentStep} totalSteps={5} />
              <div>
                <>
                  {currentStep !== 5 ? (
                    <StepButton
                      onClick={currentStep > 0 ? handleStepBack : setIndex}
                      background="#c6c4b2"
                      fill="#fff"
                    />
                  ) : (
                    ''
                  )}
                  {currentStep !== 4 && currentStep !== 5 ? (
                    <StepButton
                      disabled
                      onClick={handleStepForward}
                      background="#fff"
                      fill="#9c997c"
                      forward
                    />
                  ) : (
                    ''
                  )}
                </>
              </div>
              <CloseButton
                onClick={handleClose}
                width={isMedium ? 20 : 40}
                strokeWidth={isMedium ? 4 : 2}
              />
            </Container>
          )
      )}
    </Portal>
  )
}
WorkshopApplication.propTypes = {
  onClose: PropTypes.func.isRequired,
}
export default WorkshopApplication
