import React from "react"
import PriceIcon from "../../../icons/Price"
import FlipCard from "../../FlipCard"
import { HorizontalCard } from "../../sections"

const Paket = ({ asideBg }) => (
  <HorizontalCard startLeft className="usluga">
    <article>
      <div className="content">
        <h3>
          <strong>5. DOULA PAKET:</strong> 2 x priprema tate i mame za porod i
          babinje
        </h3>
        <h4>najbolje treće tromjesečje</h4>
        <p>
          Peti paket uključuje dva susreta na kojima ćemo obraditi teme
          trudnoća, porod i roditeljstvo. Nakon njih Vi i Vaš partner bit ćete
          spremni za polagan, smiren i opušten porod i roditeljstvo. Znat ćete
          kako si pomoći kada porođaj krene kod kuće, kako disati, koje položaje
          zauzeti, kako će tata biti prava podrška na porodu. Napravit ćemo plan
          poroda i proći sve teme koje vas zanimaju.
        </p>
      </div>
    </article>
    <aside>
      <FlipCard
        frontBgImage={asideBg}
        front={
          <>
            <div className="number">5</div>
            <div className="flipBanner">
              klikni za prikaz cijene
              <PriceIcon />
            </div>
          </>
        }
        back={
          <div className="paket">
            <header>
              cijena <strong>156 eur*</strong>
            </header>
            <div className="details">
              <span>
                način plaćanja <strong>Uplata na račun</strong>
              </span>
              <span>
                naziv <strong>DOULA SANJA, vl. Sanja Bukač Štritof</strong>
              </span>
              <span>
                adresa <strong>Ulica kneza Domagoja 4, Zagreb</strong>
              </span>
              <span>
                matični broj <strong>98046977-000</strong>
              </span>
              <span>
                iban <strong>HR5323600001102807645</strong>
              </span>
            </div>
            <em>* Plaćanje se vrši prema ugovoru o suradnji.</em>
          </div>
        }
        startLeft
      />
    </aside>
  </HorizontalCard>
)
export default Paket
