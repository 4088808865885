import React, { useMemo, useCallback } from "react"
import format from "date-fns/format"
import { HorizontalCard } from "../../sections"
import FlipCard from "../../FlipCard"
import ClockIcon from "../../../icons/Clock"
import Button from "../../Button"

const Workshop = ({ asideBg, allDetails, setWorkshopApplication }) => {
  const workshopDetails = allDetails.edges.find(
    edge => edge.node.workshop === "workshop2"
  ).node
  const isDateInFuture = useMemo(
    () => new Date(workshopDetails.date) > new Date(),
    [workshopDetails]
  )
  const openApplication = useCallback(
    () => setWorkshopApplication(workshopDetails),
    [setWorkshopApplication, workshopDetails]
  )
  return (
    <HorizontalCard olive className="radionica">
      <article>
        <div className="content">
          <h3>
            <strong>
              Zdrava prehrana kroz trudnoću i majčinstvo &amp; Doula - priprema
              za porod
            </strong>
          </h3>
          <h4>jednodnevna radionica; uživo; 90 min</h4>
          <p>
            Radionica za mame i trudnice na kojoj ćete naučiti što smijete jesti
            kroz trudnoću i kako se hraniti u danima kada dođete kući iz
            rodilišta i počnete dojiti svoje dijete. Na radionici ćete saznati
            kako Vam partner može pomoći i olakšati Vam trudove kod kuće i u
            bolnici, kada krenuti u rodilište, kako disati i tiskati, kako se
            postavljati u položaje na porodu, kako komunicirati s bolničkim
            osobljem, kako biti što prisniji te kako se nositi s blagodatima
            koje donosi majčinstvo.
          </p>
        </div>
        <footer>
          <Button olive onClick={openApplication}>
            Prijavi se!
          </Button>
        </footer>
      </article>
      <aside>
        <FlipCard
          frontBgImage={asideBg}
          front={
            <div className="flipBanner">
              klikni za prikaz cijene
              <ClockIcon />
            </div>
          }
          back={
            <div className="workshop">
              {isDateInFuture ? (
                <header>
                  sljedeća radionica: <br />
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </header>
              ) : (
                <header>
                  trenutno nema <br />
                  dostupnih termina.
                </header>
              )}
              <div className="address">{workshopDetails.address}</div>
              <div className="price">271,22 kn (36 eur)</div>
            </div>
          }
        />
      </aside>
    </HorizontalCard>
  )
}
export default Workshop
