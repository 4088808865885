import React from 'react'
import PropTypes from 'prop-types'

const SvgPrice = ({ width = 32, fill = '#fff' }) => (
  <svg width={width} fill="none" viewBox="0 0 32 32">
    <path
      d="M31.487 16c0 8.554-6.933 15.487-15.487 15.487S.513 24.554.513 16 7.446.513 16 .513 31.487 7.446 31.487 16zM16 3.52C9.108 3.52 3.52 9.108 3.52 16c0 6.893 5.588 12.48 12.48 12.48 6.893 0 12.48-5.587 12.48-12.48 0-6.892-5.587-12.48-12.48-12.48z"
      fill={fill}
    />
    <path
      d="M17.822 23.126v3.514h-2.877v-3.365c-2.194-.191-3.727-1.086-4.75-2.09l2.045-2.234c1.023.872 2.152 1.363 3.473 1.363 1.214 0 1.746-.533 1.746-1.257 0-.83-.448-1.193-2.279-1.769-3.409-1.065-4.388-2.513-4.388-4.558 0-2.28 1.705-3.813 4.154-4.196V5.359h2.876v3.195c1.492.235 2.683.853 3.621 1.79l-1.83 2.173c-.94-.747-1.896-1.13-3.026-1.13-1.002 0-1.512.383-1.512 1 0 .725.532.96 2.533 1.599 2.897.936 4.199 2.194 4.199 4.708-.002 2.217-1.473 3.878-3.985 4.432z"
      fill={fill}
    />
  </svg>
)

SvgPrice.propTypes = {
  width: PropTypes.number,
  fill: PropTypes.string,
}

export default SvgPrice
