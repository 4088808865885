import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import PropTypes from 'prop-types'
import useScroll from '../hooks/useScroll'

export const CirclesContainer = styled.span`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`

const SvgStyles = styled.svg`
  position: absolute;
`

const Circle = ({ radius, fill, position, align }) => (
  <SvgStyles
    position={position}
    align={align}
    viewBox="0 0 2 2"
    width={radius}
    fill={fill}
  >
    <circle cx="1" cy="1" r="1" />
  </SvgStyles>
)
Circle.propTypes = {
  radius: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number.isRequired),
  align: PropTypes.arrayOf(PropTypes.string.isRequired),
}

const FloatingCircle = ({
  depth = 2,
  sectionRef,
  radius = 50,
  fill = '#FCEBF1',
  position = [100, 100],
  align = ['left', 'top'],
}) => {
  const [styles, set] = useSpring(() => ({
    [align[0]]: `${position[0]}px`,
    [align[1]]: `${position[1]}px`,
    position: 'absolute',
    width: radius,
    height: radius,
    zIndex: 0,
    transform: `translate3d(0, ${300 * (depth / 4) || 0}px, 0)`,
    config: { mass: depth, friction: 5, tension: 30 },
  }))

  useScroll(
    ({ current }) =>
      set({
        transform: `translate3d(0, ${current * (depth / 4) || 0}px, 0)`,
      }),
    [],
    sectionRef
  )

  return (
    <animated.div style={styles}>
      <Circle radius={radius} fill={fill} position={position} align={align} />
    </animated.div>
  )
}
FloatingCircle.propTypes = {
  sectionRef: PropTypes.node.isRequired,
  depth: PropTypes.number,
  radius: PropTypes.number,
  fill: PropTypes.string,
  position: PropTypes.arrayOf(PropTypes.number.isRequired),
  align: PropTypes.arrayOf(PropTypes.string.isRequired),
}

export default React.memo(FloatingCircle)
