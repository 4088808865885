import React, { useMemo, useState, useEffect } from 'react'
import format from 'date-fns/format'
import styled from 'styled-components'
import useMedia from 'use-media'
import { useTransition } from 'react-spring'
import Portal from '../Portal'
import { Container, CloseButton } from '../FullScreenForm'
import Button from '../Button'

const Header = styled.header`
  color: #fff;
  text-align: center;
  width: 100%;
  h2 {
    font-weight: normal;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 60px;
    display: inline-block;
  }
  div {
    margin-bottom: 140px;
  }
  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 300;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: 400px) {
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
    }
    h4 {
      font-size: 12px;
      line-height: 18px;
    }
    div {
      width: 330px;
      margin-bottom: 90px;
    }
  }
`

const Body = styled.div`
  margin-bottom: 120px;
  .date {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    font-variant: small-caps;
    margin-bottom: 30px;
  }
  .address {
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    color: #fff;
  }

  @media (max-width: 400px) {
    margin-bottom: 76px;
    .date {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .address {
      font-size: 24px;
      line-height: 30px;
      font-weight: 300;
    }
  }
`

const WorkshopDetails = ({ onClose, workshopDetails, setCurrentStep }) => {
  const [toggle, setToggle] = useState(false)

  const isMedium = useMedia({ maxWidth: 1100 })
  useEffect(() => {
    setToggle(true)
  }, [])
  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }
  const workshopTitle = useMemo(() => {
    if (workshopDetails.workshop === 'workshop2')
      return [
        workshopDetails.type,
        'jednodnevna radionica; mjesto održavanja - Udruga Altona',
      ]
    if (workshopDetails.workshop === 'workshop6')
      return [
        workshopDetails.type,
        'besplatna radionica za trudnice; 90 minuta',
      ]
    if (workshopDetails.workshop === 'workshop7')
      return [workshopDetails.type, '']
    if (workshopDetails.workshop === 'workshop8')
      return [
        workshopDetails.type,
        'mjesto održavanja - Hrvatska udruga doula; pojedinačno ili u paru; 150 minuta',
      ]
  }, [workshopDetails])
  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <CloseButton
                onClick={handleClose}
                width={isMedium ? 20 : 40}
                strokeWidth={isMedium ? 4 : 2}
              />
              <Header>
                <h2>prijava na radionicu</h2>
                <div>
                  <h3>{workshopTitle[0]}</h3>
                  <h4>{workshopTitle[1]}</h4>
                </div>
              </Header>
              <Body>
                <div className="date">
                  {' '}
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </div>
                <div className="address">{workshopDetails.address}</div>
              </Body>
              <Button
                onClick={() => setCurrentStep(0)}
                style={{ fontWeight: 500 }}
              >
                kreni s prijavom
              </Button>
            </Container>
          )
      )}
    </Portal>
  )
}

export default WorkshopDetails
