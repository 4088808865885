import React from "react"
import PriceIcon from "../../../icons/Price"
import FlipCard from "../../FlipCard"
import { HorizontalCard } from "../../sections"

const Paket = ({ asideBg }) => (
  <HorizontalCard startLeft className="usluga">
    <article>
      <div className="content">
        <h3>
          <strong>3. DOULA PAKET:</strong> 1 x konzultacije, porod, 1 x babinje
        </h3>
        <h4>pred porod</h4>
        <p>
          Treći paket uključuje jedan susret na kojem ćemo obraditi teme porod i
          roditeljstvo. Nakon njega bit ćemo spremni za polagan, smiren i
          opušten porod i roditeljstvo. Uz susret paket uključuje tri tjedna
          dežurstva u kojima sam Vama i Vašem partneru na raspolaganju,
          telefonski, u vašem domu i u rodilištu. Bebicu ćemo dočekati zajedno
          kao tim, izmjenjujući se ili kako Vama najbolje odgovara. U danima
          babinja posjetit ću Vas i pomoći Vam da budete smireni i sigurni u
          sebe, a ja ću Vam biti podrška u trenutcima kada me najviše budete
          trebali.
        </p>
      </div>
    </article>
    <aside>
      <FlipCard
        frontBgImage={asideBg}
        front={
          <>
            <div className="number">3</div>
            <div className="flipBanner">
              klikni za prikaz cijene
              <PriceIcon />
            </div>
          </>
        }
        back={
          <div className="paket">
            <header>
              cijena <strong>636 eur*</strong>
            </header>
            <div className="details">
              <span>
                način plaćanja <strong>Uplata na račun</strong>
              </span>
              <span>
                naziv <strong>DOULA SANJA, vl. Sanja Bukač Štritof</strong>
              </span>
              <span>
                adresa <strong>Ulica kneza Domagoja 4, Zagreb</strong>
              </span>
              <span>
                matični broj <strong>98046977-000</strong>
              </span>
              <span>
                iban <strong>HR5323600001102807645</strong>
              </span>
            </div>
            <em>* Plaćanje se vrši prema ugovoru o suradnji.</em>
          </div>
        }
        startLeft
      />
    </aside>
  </HorizontalCard>
)
export default Paket
