import React, { useMemo, useCallback } from "react"
import format from "date-fns/format"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useMedia from "use-media"
import { HorizontalCard } from "../../sections"
import FlipCard from "../../FlipCard"
import ClockIcon from "../../../icons/Clock"
import Button from "../../Button"

const Workshop = ({ asideBg, allDetails, setWorkshopApplication }) => {
  const hideLogo = useMedia({ maxWidth: 1200 })

  const data = useStaticQuery(graphql`
    query {
      zdravaGlavaLogo: file(
        relativePath: { eq: "radionice/zdrava-glava-logo.png" }
      ) {
        childImageSharp {
          fixed(width: 184, height: 46) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const workshopDetails = allDetails.edges.find(
    edge => edge.node.workshop === "workshop4"
  ).node
  const isDateInFuture = useMemo(
    () => new Date(workshopDetails.date) > new Date(),
    [workshopDetails]
  )
  const openApplication = useCallback(
    () => setWorkshopApplication(workshopDetails),
    [setWorkshopApplication, workshopDetails]
  )
  return (
    <HorizontalCard olive className="radionica">
      <article>
        <div className="content">
          <h3>
            <strong>
              Spremni za porođaj uz opuštanje, zauzimanje položaja, primjenu
              disanja, tiskanja, masaže međice
            </strong>
          </h3>
          <h4>jednodnevna radionica; uživo; 90 min</h4>
          <p>
            Pravilno opuštanje, zauzimanje položaja kroz porod, primjena masaže,
            pravilno disanje i tiskanje, promjena položaja u rađaonici, masaža
            međice i pravilna komunikacija s bolničkim osobljem. Ova praktična
            radionica će vas oduševiti i naučiti praksi iz prve ruke.
          </p>
        </div>
        <footer>
          <Button olive onClick={openApplication}>
            Prijavi se!
          </Button>
        </footer>
      </article>
      <aside>
        <FlipCard
          frontBgImage={asideBg}
          front={
            <div className="flipBanner">
              klikni za prikaz cijene
              <ClockIcon />
            </div>
          }
          back={
            <div className="workshop">
              {isDateInFuture ? (
                <header>
                  sljedeća radionica: <br />
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </header>
              ) : (
                <header>
                  trenutno nema <br />
                  dostupnih termina.
                </header>
              )}
              <div className="address">{workshopDetails.address}</div>
              <div className="price">271,22 kn (36 eur)</div>
            </div>
          }
        />
      </aside>
    </HorizontalCard>
  )
}
export default Workshop
