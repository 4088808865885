import styled from 'styled-components'
import { colors } from './theme'

export const VerticalCardsContainer = styled.section`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 100px;
  justify-items: center;
  margin: 5rem 0;
  width: 100%;
  max-width: 1400px;
  @media (max-width: 1340px) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 80px;
  }
  @media (max-width: 800px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    margin: 2rem 0;
  }

  @media (max-width: 380px) {
    padding: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & > *:nth-child(2) {
      -ms-grid-column: 2;
    }
    & > *:nth-child(3) {
      -ms-grid-column: 3;
    }
    & > *:nth-child(4) {
      -ms-grid-column: 4;
    }
    & > *:nth-child(5) {
      -ms-grid-column: 5;
    }
    & > *:nth-child(6) {
      -ms-grid-column: 6;
    }
    & > *:nth-child(7) {
      -ms-grid-column: 7;
    }
    & > *:nth-child(8) {
      -ms-grid-column: 8;
    }
    & > *:nth-child(9) {
      -ms-grid-column: 9;
    }
    & > *:nth-child(10) {
      -ms-grid-column: 10;
    }
  }
`

export default styled.article`
  position: relative;
  width: 360px;
  height: 800px;
  background: #fff;

  .profile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 360px;
    height: 360px;
  }
  div {
    padding: 1.2rem 0.3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 360px);
  }
  h3 {
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    color: ${colors.greymid};
    margin: 0 0 0.2rem;
  }
  h4 {
    font-weight: 300;
    font-size: 0.9rem;
    text-align: center;
    color: ${colors.greymid};
    margin: 0;
  }
  span p {
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 1.4rem;
    text-align: center;
    color: ${colors.greymid};
  }
  p.subtitle {
    margin: 1.2rem auto 0;
    font-size: 1.1rem;
    width: 300px;
  }
  a.title-link,
  p.titlep {
    display: inline-block;
    width: 310px;
    margin: 20px auto;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.4rem;
    text-align: center;
    color: #c6c4b3;
  }

  /* this will align logo images horizontally across cards */
  .logo {
    height: 100px;
    width: 100%;
    position: absolute;
    top: 580px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  @media (max-width: 950px) {
    width: 315px;
    .profile-image {
      width: 315px;
      height: 315px;
    }
    div {
      height: calc(100% - 315px);
    }
    height: 702px;
    .logo {
      top: 480px;
    }

    .suradnici & {
      h3 {
        font-size: 20px;
        line-height: 17px;
      }
      h4 {
        font-size: 16px;
        line-height: 22px;
      }
      p {
        font-weight: 300;
        line-height: 26px;
        font-size: 15.5px;
      }
    }

    button {
      width: 240px;
      height: 40px;
      font-size: 14px;
    }
  }

  @media (max-width: 375px) {
    width: 300px;
    .profile-image {
      width: 300px;
      height: 300px;
    }
  }
`
