import React, { useState } from 'react'
import styled from 'styled-components'
import { PolicyAccept } from '../FullScreenForm'
import PrivacyPolicy from '../PrivacyPolicy'

const PolicyNotice = styled.header`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  max-width: 670px;
  margin: 6px 0 50px;
  em {
    text-decoration: underline;
    font-style: normal;
    cursor: pointer;
  }
  @media (max-width: 650px) {
    margin: 0 0 60px;
    font-size: 14px;
    max-width: 522px;
  }
`

const Step4 = ({ value, setValue, onSubmit }) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  return (
    <>
      {showPrivacyPolicy ? (
        <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
      ) : (
        ''
      )}
      <PolicyNotice style={{ marginTop: -17 }}>
        Pristaješ li da se tvoji osobni podaci uključujući i slike s radionice
        koriste u svrhu kontaktiranja o drugim uslugama i ponudama te u
        promidžbene svrhe sukladno{' '}
        <em onClick={() => setShowPrivacyPolicy(true)}>Politici privatnosti</em>
        :
      </PolicyNotice>
      <PolicyAccept value={value} setValue={setValue} onSubmit={onSubmit} />
    </>
  )
}

export default Step4
