import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import Tick from '../../icons/Tick'
import Cross from '../../icons/Cross'
import Button from '../Button'
import {
  Container,
  NotifyHeader,
  NotificationSubtitle,
} from '../FullScreenForm'

const FinalStep = ({ onClose, isError }) => {
  const isMobile = useMedia({ maxWidth: 670 })
  const content = isError ? (
    <>
      <Cross width={isMobile ? 60 : 80} />
      <NotifyHeader>došlo je do greške na serveru</NotifyHeader>
      <NotificationSubtitle>
        Ali zato mi se možeš javiti izravno putem mail adrese:
        <br />
        <a href="mailto:sanjadoula1@gmail.com">sanjadoula1@gmail.com</a>
      </NotificationSubtitle>
      <Button onClick={onClose}>radionice</Button>
    </>
  ) : (
    <>
      <Tick width={isMobile ? 60 : 80} />
      <NotifyHeader>tvoja je prijava zaprimljena!</NotifyHeader>
      <NotificationSubtitle>
        Na tvoju mail adresu uskoro stiže potvrda o rezervaciji. Ukoliko ti
        potvrda nije stigla, svakako provjeri svoj junk ili spam folder.
      </NotificationSubtitle>
      <Button onClick={onClose}>radionice</Button>
    </>
  )
  return <Container>{content}</Container>
}
FinalStep.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default FinalStep
