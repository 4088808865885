import format from "date-fns/format"
import React, { useMemo } from "react"
import ClockIcon from "../../../icons/Clock"
import FlipCard from "../../FlipCard"
import { HorizontalCard } from "../../sections"

const Workshop = ({ asideBg, allDetails, setWorkshopApplication }) => {
  const workshopDetails = allDetails.edges.find(
    edge => edge.node.workshop === "workshop7"
  ).node
  const isDateInFuture = useMemo(
    () => new Date(workshopDetails.date) > new Date(),
    [workshopDetails]
  )
  return (
    <HorizontalCard olive startLeft className="radionica">
      <article>
        <div className="content">
          <h3>
            <strong>
              Masaža za bebe, nježna i opuštajuća, baš kako vole male bebe i
              novorođenčad
            </strong>
          </h3>
          <h4>jednodnevna radionica za trudnice; uživo; 90 min</h4>
          <h5>
            <a href="mailto:sanjadoula1@gmail.com">sanjadoula1@gmail.com</a>
          </h5>
          <p>
            Ima li što ljepše od masaže male djece, beba i novorođenčadi? Nema!
            U lijepoj i opuštenoj atmosferi uživat ćemo u dodiru, pokretu,
            zajedničkim trenutcima. Pokazat ćemo tehniku na vašim bebama ili
            lutkama kako bi se pripremili za lijepe, tople i opuštene trenutke u
            krugu vaše male obitelji.
          </p>
        </div>
      </article>
      <aside>
        <FlipCard
          frontBgImage={asideBg}
          front={
            <div className="flipBanner">
              klikni za prikaz cijene
              <ClockIcon />
            </div>
          }
          back={
            <div className="workshop">
              {isDateInFuture ? (
                <header>
                  sljedeća radionica: <br />
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </header>
              ) : (
                <header>
                  trenutno nema <br />
                  dostupnih termina.
                </header>
              )}
              <div className="address">{workshopDetails.address}</div>
              <div className="price">Cijena: na upit</div>
            </div>
          }
          startLeft
        />
      </aside>
    </HorizontalCard>
  )
}
export default Workshop
