import React from "react"

const SvgClock = props => (
  <svg width={32} height={32} fill="none" {...props}>
    <path
      d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 28.595c-6.945 0-12.596-5.65-12.596-12.595S9.054 3.405 16 3.405c6.945 0 12.596 5.65 12.596 12.595S22.946 28.595 16 28.595z"
      fill="#fff"
    />
    <path
      d="M24.339 15.506h-7.19V6.86a1.317 1.317 0 00-2.636 0v9.963c0 .728.59 1.318 1.318 1.318h8.508a1.317 1.317 0 100-2.635z"
      fill="#fff"
    />
  </svg>
)

export default SvgClock
