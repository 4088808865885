import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import FloatingCircle, { CirclesContainer } from "../../FloatingCircle"
import {
  TitleSection,
  CircleSection,
  HorizontalCardContainer,
} from "../../sections"
import Paket1 from "./Paket1"
import Paket2 from "./Paket2"
import Paket3 from "./Paket3"
import Paket4 from "./Paket4"
import Paket5 from "./Paket5"
import Paket6 from "./Paket6"
import Paket7 from "./Paket7"

const PaketiUsluga = ({ isIntersecting }) => {
  const data = useStaticQuery(graphql`
    query {
      dotsOlive: file(relativePath: { eq: "dots--olive.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dotsPinkDark: file(relativePath: { eq: "dots--pinkdark.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dotsPinkLight: file(relativePath: { eq: "dots--pinklight.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const ref = useRef()
  return (
    <>
      <TitleSection bg="#F29EBA">
        <div>
          <h2>Paketi usluga *</h2>
        </div>
      </TitleSection>
      <CircleSection bg="pinklightest" ref={ref}>
        {isIntersecting ? (
          <CirclesContainer>
            <FloatingCircle
              sectionRef={ref}
              depth={3}
              radius={128}
              align={["right", "top"]}
              position={[-10, 323]}
              fill="#FCEBF1"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={400}
              align={["left", "top"]}
              position={[-80, 683]}
              fill="#FDF1F4"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.3}
              radius={159}
              align={["right", "top"]}
              position={[-36, 1459]}
              fill="#FDF2F5"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.3}
              radius={159}
              align={["left", "top"]}
              position={[-170, 1730]}
              fill="#FCEBF1"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={3}
              radius={70}
              align={["left", "bottom"]}
              position={[27, 764]}
              fill="#FCEBF1"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={550}
              align={["right", "bottom"]}
              position={[-240, -278]}
              fill="#FDF1F4"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.1}
              radius={178}
              align={["left", "bottom"]}
              position={[-29, -487]}
              fill="#FCE4EC"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.3}
              radius={391}
              align={["right", "bottom"]}
              position={[344, -1018]}
              fill="#ffffff"
            />
          </CirclesContainer>
        ) : (
          ""
        )}

        <HorizontalCardContainer>
          <Paket1 asideBg={data.dotsPinkDark.childImageSharp.fluid} />
          <Paket2 asideBg={data.dotsPinkLight.childImageSharp.fluid} />
          <Paket3 asideBg={data.dotsOlive.childImageSharp.fluid} />
          <Paket4 asideBg={data.dotsPinkDark.childImageSharp.fluid} />
          <Paket5 asideBg={data.dotsPinkLight.childImageSharp.fluid} />
          <Paket6 asideBg={data.dotsOlive.childImageSharp.fluid} />
          <Paket7 asideBg={data.dotsPinkDark.childImageSharp.fluid} />
        </HorizontalCardContainer>

        <div style={{ zIndex: 0 }}>
          * porod podrazumijeva samo dio kod kuće ili dio kod kuće i u bolnici
          ili samo u bolnici
          <br />
          * po dogovoru dogovoreni paket se računa bez obzira da li je
          realiziran, termin je rezerviran samo za tu Doula mamu
          <br />
          * troškovi puta se dodatno naplaćuju u slučaju dolaska izvan termina
          ili putovanja izvan Zagreba
          <br />
          * u slučaju bolesti ili izvanrednih situacija porodu će prisustvovati
          zamjenska doula
          <br />
        </div>
        {/* // todo: make this more neat and semantic */}
      </CircleSection>
    </>
  )
}

PaketiUsluga.propTypes = {
  isIntersecting: PropTypes.bool,
}

export default PaketiUsluga
