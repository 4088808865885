import React from 'react'
import { FormInput } from '../FullScreenForm'

const Step1 = ({ value, setValue }) => (
  <>
    <h2>Tvoje ime</h2>
    <h4>puno ime i prezime</h4>
    <FormInput
      autoFocus
      type="text"
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  </>
)

export default Step1
