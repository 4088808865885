import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FloatingCircle, { CirclesContainer } from '../FloatingCircle'
import { TitleSection, CircleSection } from '../sections'
import Button from '../Button'
import VerticalCard, { VerticalCardsContainer } from '../VerticalCard'
import desetPDF from '../../assets/10 koraka za rodilista.pdf'
import poveljaPdf from '../../assets/Povelja o opcim pravima rodilja.pdf'
import opcaPdf from '../../assets/Opca deklaracija o ljudskim pravima.pdf'

const Darujem = ({ isIntersecting }) => {
  const data = useStaticQuery(graphql`
    query {
      korisneDeset: file(relativePath: { eq: "korisne_poveznice/deset.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      korisneHrvatska: file(
        relativePath: { eq: "korisne_poveznice/hrvatska.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      korisneOpca: file(relativePath: { eq: "korisne_poveznice/opca.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      korisnePopis: file(relativePath: { eq: "korisne_poveznice/popis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      korisnePovelja: file(
        relativePath: { eq: "korisne_poveznice/povelja.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      korisneUpute: file(relativePath: { eq: "korisne_poveznice/upute.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rodaLogo: file(relativePath: { eq: "roda_logo.png" }) {
        childImageSharp {
          fixed(width: 103, height: 93) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hrvatskaUdrugaDoulaLogo: file(
        relativePath: { eq: "hrvatska_udruga_doula_logo.png" }
      ) {
        childImageSharp {
          fixed(width: 266, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const ref = useRef()
  return (
    <>
      <TitleSection bg="#C6C4B3">
        <div>
          <h2>Korisne poveznice</h2>
        </div>
      </TitleSection>
      <CircleSection bg="olivelightest" ref={ref} noSmallPadding>
        {isIntersecting ? (
          <CirclesContainer>
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={623}
              align={['right', 'top']}
              position={[-214, 344]}
              fill="#F6F6F3"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.1}
              radius={599}
              align={['left', 'top']}
              position={[-194, 960]}
              fill="#F3F3EF"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.4}
              radius={252}
              align={['left', 'bottom']}
              position={[27, -252]}
              fill="#EEEEE9"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.7}
              radius={187}
              align={['right', 'bottom']}
              position={[-44, -105]}
              fill="#EEEEE9"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.9}
              radius={136}
              align={['right', 'bottom']}
              position={[306, -326]}
              fill="#F3F3EF"
            />
          </CirclesContainer>
        ) : (
          ''
        )}
        <VerticalCardsContainer>
          <VerticalCard>
            <Img
              fluid={data.korisneUpute.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Upute za dojenje</h3>
                <p className="subtitle">Skinuti upute sa stranice:</p>
              </span>
              <div className="logo">
                <a
                  href="http://www.roda.hr/media/attachments/portal/dojenje/osnove-dojenja-hrv.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fixed={data.rodaLogo.childImageSharp.fixed} />
                </a>
              </div>
              <Button
                bg="#F5F5F2"
                onClick={() =>
                  window.open(
                    'http://www.roda.hr/media/attachments/portal/dojenje/osnove-dojenja-hrv.pdf',
                    '_blank'
                  )
                }
              >
                poveznica na stranicu
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.korisnePopis.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Popis rodilišta u Hrvatskoj i uvjeti u njima </h3>
              </span>
              <div className="logo">
                <a
                  href="http://rodilista.roda.hr/rodilista/rodilista-u-hrvatskoj/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img fixed={data.rodaLogo.childImageSharp.fixed} />
                </a>
              </div>
              <Button
                bg="#F5F5F2"
                onClick={() =>
                  window.open(
                    'http://rodilista.roda.hr/rodilista/rodilista-u-hrvatskoj/',
                    '_blank'
                  )
                }
              >
                poveznica na stranicu
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.korisneHrvatska.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Hrvatska udruga doula</h3>
              </span>
              <div className="logo">
                <a
                  href="https://www.doula.hr/mentoring.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={data.hrvatskaUdrugaDoulaLogo.childImageSharp.fixed}
                  />
                </a>
              </div>
              <Button
                bg="#F5F5F2"
                onClick={() =>
                  window.open('https://www.doula.hr/mentoring.php', '_blank')
                }
              >
                poveznica na stranicu
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.korisneDeset.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Deset koraka za rodilišta – “Prijatelje majki”</h3>
                <p className="titlep">
                  Međunarodno udruženje ginekologa i opstetričara (FIGO)
                </p>
              </span>
              <Button bg="#F5F5F2" onClick={() => window.open(desetPDF)}>
                preuzmi PDF
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.korisnePovelja.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>
                  Povelja o općim pravima rodilja <br />
                  &nbsp;
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://rodilista.roda.hr/media/attachments/rmc-poster-hrv.pdf"
                  className="title-link"
                >
                  Preuzeto sa stranica Udruge RODA
                </a>
              </span>
              <Button bg="#F5F5F2" onClick={() => window.open(poveljaPdf)}>
                preuzmi PDF
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.korisneOpca.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Opća deklaracija o ljudskim pravima </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.pariter.hr/wp-content/uploads/2014/10/opca_deklaracija_o_ljudskim-pravima.pdf"
                  className="title-link"
                >
                  Preuzeto sa stranica Udruge Pariter
                </a>
              </span>
              <Button bg="#F5F5F2" onClick={() => window.open(opcaPdf)}>
                preuzmi PDF
              </Button>
            </div>
          </VerticalCard>
        </VerticalCardsContainer>
      </CircleSection>
    </>
  )
}

export default Darujem
