import format from "date-fns/format"
import React, { useMemo } from "react"
import ClockIcon from "../../../icons/Clock"
import FlipCard from "../../FlipCard"
import { HorizontalCard } from "../../sections"

const Workshop = ({ asideBg, allDetails, setWorkshopApplication }) => {
  const workshopDetails = allDetails.edges.find(
    edge => edge.node.workshop === "workshop9"
  ).node
  const isDateInFuture = useMemo(
    () => new Date(workshopDetails.date) > new Date(),
    [workshopDetails]
  )
  return (
    <HorizontalCard olive startLeft className="radionica">
      <article>
        <div className="content">
          <h3>
            <strong>
              Ciklus radionica pripreme za porod i babinje kroz 10 webinara
            </strong>
          </h3>
          <h4>online; termin prema vašim željama</h4>
          <h5>
            <a href="mailto:sanjadoula1@gmail.com">sanjadoula1@gmail.com</a>
          </h5>
          <p>
            Lagan, opušten i lijep porod u samo 10 koraka. Prijedlozi
            literature. Podrška kroz nedoumice. Konzultacije sa specijalistom
            ginekologije i opstetricije putem maila. Plan poroda i smjernice za
            hrvatska rodilišta i patronažu. Meditacije i vizualizacije za
            stresne periode. Pristup ‘Sretnim karticama za opuštanje, sigurnost
            i lijepo iskustvo poroda’.
          </p>
        </div>
      </article>
      <aside>
        <FlipCard
          frontBgImage={asideBg}
          front={
            <div className="flipBanner">
              klikni za prikaz cijene
              <ClockIcon />
            </div>
          }
          back={
            <div className="workshop">
              {isDateInFuture ? (
                <header>
                  sljedeća radionica: <br />
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </header>
              ) : (
                <header>prema dogovoru</header>
              )}
              <div className="address">{workshopDetails.address}</div>
              <div className="price">
                490 kn (65 eur)
                <br />
                <span style={{ fontStyle: "italic" }}>
                  stvarna vrijednost 1800 kn (240 eur)
                </span>
              </div>
            </div>
          }
          startLeft
        />
      </aside>
    </HorizontalCard>
  )
}
export default Workshop
