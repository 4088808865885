import React from 'react'
import { HorizontalCard } from '../../sections'
import FlipCard from '../../FlipCard'
import PriceIcon from '../../../icons/Price'

const Paket = ({ asideBg }) => (
  <HorizontalCard startLeft className="usluga">
    <article>
      <div className="content">
        <h3>
          <strong>7. DOULA PAKET:</strong> hitna pomoć – babinje
        </h3>
        <h4>prema dogovoru</h4>
        <p>
          Sedmi paket uključuje pomoć koja će Vam biti potrebna u danima
          babinja, a nedostupna je od strane patronažnih sestara i zdravstvenih
          djelatnika. SOS.
        </p>
      </div>
    </article>
    <aside>
      <FlipCard
        frontBgImage={asideBg}
        front={
          <>
            <div className="number">7</div>
            <div className="flipBanner">
              klikni za prikaz cijene
              <PriceIcon />
            </div>
          </>
        }
        back={
          <div className="paket">
            <header>
              cijena <strong>Na upit</strong>
            </header>
            <div className="details">
              <span>
                način plaćanja <strong>Uplata na račun</strong>
              </span>
              <span>
                naziv <strong>DOULA SANJA, vl. Sanja Bukač Štritof</strong>
              </span>
              <span>
                adresa <strong>Ulica kneza Domagoja 4, Zagreb</strong>
              </span>
              <span>
                matični broj <strong>98046977-000</strong>
              </span>
              <span>
                iban <strong>HR5323600001102807645</strong>
              </span>
            </div>
            <em>* Plaćanje se vrši prema ugovoru o suradnji.</em>
          </div>
        }
        startLeft
      />
    </aside>
  </HorizontalCard>
)
export default Paket
