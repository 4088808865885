import React, { useState, useEffect } from 'react'
import useMedia from 'use-media'
import { useTransition } from 'react-spring'
import Portal from '../Portal'
import {
  Container,
  CloseButton,
  NotifyHeader,
  NotificationSubtitle,
} from '../FullScreenForm'
import Button from '../Button'
import Cross from '../../icons/Cross'

const NoWorkshop = ({ onClose }) => {
  const [toggle, setToggle] = useState(false)
  useEffect(() => {
    setToggle(true)
  }, [])
  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }

  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const isMedium = useMedia({ maxWidth: 1100 })
  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <CloseButton
                onClick={handleClose}
                width={isMedium ? 20 : 40}
                strokeWidth={isMedium ? 4 : 2}
              />
              <Cross style={{ marginBottom: 100 }} width={isMedium ? 60 : 80} />
              <NotifyHeader>trenutno nema dostupnih termina</NotifyHeader>
              <NotificationSubtitle>
                provjeri ponovno za nekoliko dana
              </NotificationSubtitle>
              <Button onClick={onClose}>radionice</Button>
            </Container>
          )
      )}
    </Portal>
  )
}

export default NoWorkshop
