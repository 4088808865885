import React, { useRef } from 'react'
import FloatingCircle, { CirclesContainer } from '../FloatingCircle'
import { TitleSection, CircleSection } from '../sections'

const DoulaMentor = ({ isIntersecting }) => {
  const ref = useRef()
  return (
    <>
      <TitleSection bg="#F8CAD5">
        <div>
          <h2>Doula mentor</h2>
        </div>
      </TitleSection>
      <CircleSection bg="pinktouch" ref={ref}>
        {isIntersecting ? (
          <CirclesContainer>
            <FloatingCircle
              sectionRef={ref}
              depth={2.1}
              radius={182}
              align={['left', 'top']}
              position={[-40, 126]}
              fill="#FCDEE9"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={550}
              align={['right', 'top']}
              position={[-370, 96]}
              fill="#ffffff"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.7}
              radius={134}
              align={['left', 'bottom']}
              position={[141, 152]}
              fill="#FDECF1"
            />
          </CirclesContainer>
        ) : (
          ''
        )}
        <p className="doula-mentor">
          „Tiha pratnja kroz trudnoću, na porodu i u danima babinja“ tako bi ja
          opisala sebe kao Doulu, ženu i mentoricu. Otkako sam se prije pet
          godina upustila u ovu čarobnu avanturu upoznala sam puno divnih
          parova, trudnica, dječice i stekla puno novih prijatelja. Spoznala sam
          da su trudnoća, radost iščekivanja, rođenje djeteta i majčinstvo
          najljepši i najvažniji događaji u našem životu. Biti Doula divan je
          poziv koji vam daje prekrasan osjećaj koji teško može dati bilo koje
          drugo zanimanje, stoga sam odlučila postati Doula mentorica i svojim
          novim kolegicama pružiti jednaki užitak. Na vašem putu želim vam biti
          oslonac u svim situacijama na koje ćete nailaziti i pružiti vam
          sigurnu ruku. Svoju ljubav i pažnju poklanjam vam na dar. &lt;3
        </p>
      </CircleSection>
    </>
  )
}

export default DoulaMentor
