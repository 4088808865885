import React, { useMemo, useCallback } from "react"
import format from "date-fns/format"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useMedia from "use-media"
import { HorizontalCard } from "../../sections"
import FlipCard from "../../FlipCard"
import ClockIcon from "../../../icons/Clock"
import Button from "../../Button"

const Workshop = ({
  asideBg,
  allDetails,
  newTabLink,
  setWorkshopApplication,
}) => {
  const hideLogo = useMedia({ maxWidth: 1200 })

  const data = useStaticQuery(graphql`
    query {
      mamaSoonLogo: file(relativePath: { eq: "radionice/mama-soon-logo.png" }) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const workshopDetails = allDetails.edges.find(
    edge => edge.node.workshop === "workshop5"
  ).node
  const isDateInFuture = useMemo(
    () => new Date(workshopDetails.date) > new Date(),
    [workshopDetails]
  )
  const openApplication = useCallback(
    () => setWorkshopApplication(workshopDetails),
    [setWorkshopApplication, workshopDetails]
  )
  return (
    <HorizontalCard olive startLeft className="radionica">
      <article>
        <div className="content">
          <h3>
            <strong>
              Mama, tata i beba: dojenje, njega, pažnja, ljubav i naš odnos
            </strong>
          </h3>
          <h4>jednodnevna radionica; uživo; 90 min</h4>
          <p>
            Na radionici ćemo naučiti kako se nositi s novim izazovima, kako
            olakšati sebi i partneru te kako istinski uživati u našem odnosu i
            roditeljstvu. Teme: očekivanja na odjelu babinjača, organizam i
            novorođenče nakon porođaja, dani babinja - očekivanja i stvarnost,
            važnost kontakta mame i bebe, uspješno dojenje, sigurnost djeteta,
            bebina osjetila, strahovi budućeg tate, tata u obitelji, uspješno
            roditeljstvo.
          </p>
        </div>
        <footer>
          <Button olive onClick={openApplication}>
            Prijavi se!
          </Button>
        </footer>
      </article>
      <aside>
        <FlipCard
          frontBgImage={asideBg}
          front={
            <div className="flipBanner">
              klikni za prikaz cijene
              <ClockIcon />
            </div>
          }
          back={
            <div className="workshop">
              {isDateInFuture ? (
                <header>
                  sljedeća radionica: <br />
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </header>
              ) : (
                <header>
                  trenutno nema <br />
                  dostupnih termina.
                </header>
              )}
              <div className="address">{workshopDetails.address}</div>
              <div className="price">271,22 kn (36 eur)</div>
            </div>
          }
          startLeft
        />
      </aside>
    </HorizontalCard>
  )
}
export default Workshop
