import React, { useRef, useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import FloatingCircle, { CirclesContainer } from "../../FloatingCircle"
import {
  TitleSection,
  CircleSection,
  HorizontalCardContainer,
} from "../../sections"
import Workshop1 from "./Workshop1"
import Workshop2 from "./Workshop2"
import Workshop3 from "./Workshop3"
import Workshop4 from "./Workshop4"
import Workshop5 from "./Workshop5"
import Workshop6 from "./Workshop6"
import Workshop7 from "./Workshop7"
import Workshop8 from "./Workshop8"
import Workshop9 from "./Workshop9"
import WorkshopApplication from "../../WorkshopApplication"

const Radionice = ({ isIntersecting }) => {
  const data = useStaticQuery(graphql`
    query {
      carobnaTrudnoca: file(
        relativePath: { eq: "radionice/carobna-trudnoca.jpg" }
      ) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      carolijaTrudnoca: file(
        relativePath: { eq: "radionice/carolija-trudnoca.jpg" }
      ) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      doulaNaDar: file(relativePath: { eq: "radionice/doula-na-dar.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      laganOpusten: file(relativePath: { eq: "radionice/lagan-opusten.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mamaTataBeba: file(relativePath: { eq: "radionice/mama-tata-beba.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mamaTata: file(relativePath: { eq: "radionice/mama-tata.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tataNaPorodu: file(relativePath: { eq: "radionice/tata-na-porodu.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      zdravaPrehrana: file(
        relativePath: { eq: "radionice/zdrava-prehrana.jpg" }
      ) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      uskoroMama: file(relativePath: { eq: "radionice/uskoro-mama.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      allSanityWorkshop {
        edges {
          node {
            type
            date
            address
            workshop
          }
        }
      }
    }
  `)

  const ref = useRef()
  const [workshopApplication, setWorkshopApplication] = useState(null)
  const closeApplication = useCallback(() => setWorkshopApplication(null), [])
  const newTabLink = useCallback(url => {
    window.open(url, "_blank")
  }, [])

  return (
    <>
      <TitleSection bg="#9C997C">
        <div>
          <h2>Radionice</h2>
        </div>
      </TitleSection>
      <CircleSection bg="olivelightest" ref={ref}>
        {isIntersecting ? (
          <CirclesContainer>
            <FloatingCircle
              sectionRef={ref}
              depth={1.3}
              radius={400}
              align={["right", "top"]}
              position={[-61, 523]}
              fill="#F3F3EF"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.6}
              radius={128}
              align={["left", "top"]}
              position={[107, 990]}
              fill="#EEEEE9"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2}
              radius={251}
              align={["left", "top"]}
              position={[-66, 1853]}
              fill="#F3F3EF"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.3}
              radius={107}
              align={["left", "top"]}
              position={[540, 1397]}
              fill="#F6F6F3"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.6}
              radius={260}
              align={["right", "bottom"]}
              position={[-60, 1120]}
              fill="#EEEEE9"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.7}
              radius={120}
              align={["left", "bottom"]}
              position={[-25, 866]}
              fill="#F6F6F3"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.5}
              radius={356}
              align={["left", "bottom"]}
              position={[-86, 569]}
              fill="#F3F3EF"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.7}
              radius={131}
              align={["right", "bottom"]}
              position={[-25, 246]}
              fill="#F6F6F3"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.6}
              radius={144}
              align={["right", "bottom"]}
              position={[444, 242]}
              fill="#F6F6F3"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.6}
              radius={144}
              align={["right", "bottom"]}
              position={[168, -436]}
              fill="#ffffff"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.6}
              radius={144}
              align={["right", "bottom"]}
              position={[348, -496]}
              fill="#EEEEE9"
            />
          </CirclesContainer>
        ) : (
          ""
        )}
        <HorizontalCardContainer>
          <Workshop1
            asideBg={data.carobnaTrudnoca.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop2
            asideBg={data.zdravaPrehrana.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop3
            asideBg={data.carolijaTrudnoca.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop4
            asideBg={data.laganOpusten.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            newTabLink={newTabLink}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop5
            asideBg={data.mamaTata.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            newTabLink={newTabLink}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop6
            asideBg={data.doulaNaDar.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop7
            asideBg={data.tataNaPorodu.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop8
            asideBg={data.mamaTataBeba.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
          <Workshop9
            asideBg={data.uskoroMama.childImageSharp.fixed}
            allDetails={data.allSanityWorkshop}
            setWorkshopApplication={setWorkshopApplication}
          />
        </HorizontalCardContainer>
      </CircleSection>
      {workshopApplication && (
        <WorkshopApplication
          workshopDetails={workshopApplication}
          onClose={closeApplication}
        />
      )}
    </>
  )
}

export default Radionice
