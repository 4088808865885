import React from "react"
import PriceIcon from "../../../icons/Price"
import FlipCard from "../../FlipCard"
import { HorizontalCard } from "../../sections"

const Paket = ({ asideBg }) => (
  <HorizontalCard className="usluga">
    <article>
      <div className="content">
        <h3>
          <strong>6. VIP DOULA PAKET:</strong> 3 x individualna školica za
          trudnicu i partnera, porod, 2 x babinje, online konzultacije
        </h3>
        <h4>kroz trudnoću i babinje</h4>
        <p>
          Cjelovita školica za trudnice koja pruža potpunu podršku trudnici i
          partneru kroz trudnoću, pripremu za porod i babinje. Kontinuirani
          online susreti. Pratnja na porodu. Potpuna podrška za mamu i tatu u
          danima babinja.
        </p>
      </div>
    </article>
    <aside>
      <FlipCard
        frontBgImage={asideBg}
        front={
          <>
            <div className="number">6</div>
            <div className="flipBanner">
              klikni za prikaz cijene
              <PriceIcon />
            </div>
          </>
        }
        back={
          <div className="paket">
            <header>
              cijena <strong>1.200 eur*</strong>
            </header>
            <div className="details">
              <span>
                način plaćanja <strong>Uplata na račun</strong>
              </span>
              <span>
                naziv <strong>DOULA SANJA, vl. Sanja Bukač Štritof</strong>
              </span>
              <span>
                adresa <strong>Ulica kneza Domagoja 4, Zagreb</strong>
              </span>
              <span>
                matični broj <strong>98046977-000</strong>
              </span>
              <span>
                iban <strong>HR5323600001102807645</strong>
              </span>
            </div>
            <em>* Plaćanje se vrši prema ugovoru o suradnji.</em>
          </div>
        }
      />
    </aside>
  </HorizontalCard>
)
export default Paket
