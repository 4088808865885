import React, { useMemo, useCallback } from "react"
import format from "date-fns/format"
import { HorizontalCard } from "../../sections"
import FlipCard from "../../FlipCard"
import ClockIcon from "../../../icons/Clock"
import Button from "../../Button"

const Workshop = ({ asideBg, allDetails, setWorkshopApplication }) => {
  const workshopDetails = allDetails.edges.find(
    edge => edge.node.workshop === "workshop1"
  ).node
  const isDateInFuture = useMemo(
    () => new Date(workshopDetails.date) > new Date(),
    [workshopDetails]
  )
  const openApplication = useCallback(
    () => setWorkshopApplication(workshopDetails),
    [setWorkshopApplication, workshopDetails]
  )
  return (
    <HorizontalCard olive startLeft className="radionica">
      <article>
        <div className="content">
          <h3>
            <strong>
              Čarolija trudnoće i majčinstva, školica za trudnice i partnere
            </strong>
          </h3>
          <h4>trodnevna radionica; uživo; 3 x 90 min Vježbaonica</h4>
          <p>
            Jedina radionica koja uključuje fizičku i psihičku pripremu trudnice
            za porod i majčinstvo. Škola uključuje tri područja:
            <br />
            1. Mama i tata kroz zadnje tromjesječje i kada porod krene
            <br />
            2. Spremni za porođaj!
            <br />
            3. Mama, tata i beba
          </p>
        </div>
        <footer>
          <Button olive onClick={openApplication}>
            Prijavi se!
          </Button>
        </footer>
      </article>
      <aside>
        <FlipCard
          frontBgImage={asideBg}
          front={
            <div className="flipBanner">
              klikni za prikaz cijene
              <ClockIcon />
            </div>
          }
          back={
            <div className="workshop">
              {isDateInFuture ? (
                <header>
                  sljedeća radionica: <br />
                  {format(
                    new Date(workshopDetails.date),
                    `dd.MM.yyyy 'u' k.mm 'sati'`
                  )}
                </header>
              ) : (
                <header>
                  trenutno nema <br />
                  dostupnih termina.
                </header>
              )}
              <div className="address">{workshopDetails.address}</div>
              <div className="price">790 kn (105 eur)</div>
            </div>
          }
          startLeft
        />
      </aside>
    </HorizontalCard>
  )
}
export default Workshop
