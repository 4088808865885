import React, { useRef, useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import PaketiUsluga from '../components/usluge/PaketiUsluga'
import DoulaMentor from '../components/usluge/DoulaMentor'
import Radionice from '../components/usluge/Radionice'
import Darujem from '../components/usluge/Darujem'
import KorisnePoveznice from '../components/usluge/KorisnePoveznice'
import useOnScreen from '../hooks/useOnScreen'

const Usluge = ({ location }) => {
  const [subsection, setSubsection] = useState()

  const paketiUslugaRef = useRef()
  const paketiUslugaIntersect = useOnScreen(paketiUslugaRef)
  const doulaMentorRef = useRef()
  const doulaMentorIntersect = useOnScreen(doulaMentorRef)
  const radioniceRef = useRef()
  const radioniceIntersect = useOnScreen(radioniceRef)
  const darujemRef = useRef()
  const darujemIntersect = useOnScreen(darujemRef)
  const korisnePovezniceRef = useRef()
  const korisnePovezniceIntersect = useOnScreen(korisnePovezniceRef)

  // sub sections
  useEffect(() => {
    if (korisnePovezniceIntersect) {
      setSubsection('Korisne Poveznice')
    } else if (darujemIntersect) {
      setSubsection('Darujem')
    } else if (radioniceIntersect) {
      setSubsection('Radionice')
    } else if (doulaMentorIntersect) {
      setSubsection('Doula mentor')
    } else if (paketiUslugaIntersect) {
      setSubsection('Paketi usluga')
    } else {
      setSubsection()
    }
  }, [
    darujemIntersect,
    doulaMentorIntersect,
    korisnePovezniceIntersect,
    paketiUslugaIntersect,
    radioniceIntersect,
  ])

  // links
  // scroll areas
  useEffect(() => {
    setTimeout(() => {
      if (location.href.endsWith('darujem')) {
        darujemRef.current.scrollIntoView()
      } else if (location.href.endsWith('doula-mentor')) {
        doulaMentorRef.current.scrollIntoView()
      } else if (location.href.endsWith('korisne-poveznice')) {
        korisnePovezniceRef.current.scrollIntoView()
      } else if (location.href.endsWith('paketi-usluga')) {
        paketiUslugaRef.current.scrollIntoView()
      } else if (location.href.endsWith('radionice')) {
        radioniceRef.current.scrollIntoView()
      }
    }, 100)
  }, [location])

  // floating circles on these are only rendered when that section is in view
  return (
    <Layout subsection={subsection} footerException="usluge">
      <SEO title="Usluge" />
      <div ref={paketiUslugaRef}>
        <PaketiUsluga isIntersecting={paketiUslugaIntersect} />
      </div>
      <div ref={radioniceRef}>
        <Radionice isIntersecting={radioniceIntersect} />
      </div>
      <div ref={darujemRef}>
        <Darujem isIntersecting={darujemIntersect} />
      </div>
      <div ref={korisnePovezniceRef}>
        <KorisnePoveznice isIntersecting={korisnePovezniceIntersect} />
      </div>
      <div ref={doulaMentorRef}>
        <DoulaMentor isIntersecting={doulaMentorIntersect} />
      </div>
    </Layout>
  )
}

export default Usluge
