import React, { useRef } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import FloatingCircle, { CirclesContainer } from "../FloatingCircle"
import { TitleSection, CircleSection } from "../sections"
import Button from "../Button"
import VerticalCard, { VerticalCardsContainer } from "../VerticalCard"
import planPorodaPdf from "../../assets/PLAN PORODA.pdf"
import sretneKarticePdf from "../../assets/SRETNE KARTICE.pdf"
import smjerniceZaBolnicuPdf from "../../assets/SMJERNICE ZA BOLNICU.pdf"

const Darujem = ({ isIntersecting }) => {
  const data = useStaticQuery(graphql`
    query {
      davitaKlikLogo: file(relativePath: { eq: "davita_klik_logo.png" }) {
        childImageSharp {
          fixed(width: 102, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      croHobiceLogo: file(relativePath: { eq: "cro_hobice_logo.png" }) {
        childImageSharp {
          fixed(width: 98, height: 113) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      darujemDarujte: file(relativePath: { eq: "darujem/darujte.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujemDoulaNaDar: file(
        relativePath: { eq: "darujem/doula_na_dar.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujemPlanPoroda: file(relativePath: { eq: "darujem/plan_poroda.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujemPokloni: file(relativePath: { eq: "darujem/pokloni.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujemSlikanje: file(relativePath: { eq: "darujem/slikanje.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujemSmjerniceZaBolnicu: file(
        relativePath: { eq: "darujem/smjernice_za_bolnicu.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujemSretneKartice: file(
        relativePath: { eq: "darujem/sretne_kartice.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const ref = useRef()
  return (
    <>
      <TitleSection bg="#E8397A">
        <div>
          <h2>Darujem</h2>
        </div>
      </TitleSection>
      <CircleSection bg="pinklightest" ref={ref} noSmallPadding>
        {isIntersecting ? (
          <CirclesContainer>
            <FloatingCircle
              sectionRef={ref}
              depth={1.2}
              radius={444}
              align={["left", "top"]}
              position={[-91, 38]}
              fill="#FDF2F5"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={524}
              align={["right", "top"]}
              position={[-107, 602]}
              fill="#FDF2F5"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.2}
              radius={277}
              align={["left", "top"]}
              position={[-39, 849]}
              fill="#FDF2F5"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.7}
              radius={178}
              align={["left", "bottom"]}
              position={[227, 1042]}
              fill="#FCE4EC"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1.7}
              radius={302}
              align={["right", "bottom"]}
              position={[-60, 1001]}
              fill="#FCEBF1"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={2.5}
              radius={235}
              align={["right", "bottom"]}
              position={[465, 919]}
              fill="#ffffff"
            />
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={630}
              align={["right", "bottom"]}
              position={[108, 228]}
              fill="#FCEBF1"
            />
          </CirclesContainer>
        ) : (
          ""
        )}
        <VerticalCardsContainer>
          <VerticalCard>
            <Img
              fluid={data.darujemDoulaNaDar.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>
                  <i>Doula na dar</i>
                </h3>
                <p className="subtitle">
                  GRATIS radionica
                  <br />
                  konzultacije
                  <br />
                  prigodne akcije
                </p>
              </span>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.darujemPlanPoroda.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Plan poroda</h3>
              </span>
              <Button bg="#FEF4F7" onClick={() => window.open(planPorodaPdf)}>
                preuzmi pdf
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.darujemSretneKartice.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>
                  Sretne kartice za opuštanje, sigurnost i lijepo iskustvo
                  poroda
                </h3>
              </span>
              <Button
                bg="#FEF4F7"
                onClick={() => window.open(sretneKarticePdf)}
              >
                preuzmi pdf
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.darujemSmjerniceZaBolnicu.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Smjernice za bolnicu</h3>
              </span>
              <Button
                bg="#FEF4F7"
                onClick={() => window.open(smjerniceZaBolnicuPdf)}
              >
                preuzmi pdf
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.darujemSlikanje.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Slikanje za cijelu obitelj</h3>
                <p className="subtitle">poklon bon od 300 kuna</p>
              </span>
              <a
                href="https://www.facebook.com/davitaklik/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fixed={data.davitaKlikLogo.childImageSharp.fixed} />
              </a>
              <Button
                bg="#FEF4F7"
                onClick={() =>
                  window.open("https://www.facebook.com/davitaklik/", "_blank")
                }
              >
                poveznica na stranicu
              </Button>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.darujemPokloni.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Pokloni za djecu</h3>
                <p className="subtitle">
                  Slikovnice, lutkice, potrepštine za bebe
                </p>
              </span>
            </div>
          </VerticalCard>
          <VerticalCard>
            <Img
              fluid={data.darujemDarujte.childImageSharp.fluid}
              className="profile-image"
            />
            <div>
              <span>
                <h3>Darujte svojim bližnjima pomoć u obliku poklon-bona!</h3>
                <p className="subtitle">
                  Pred Vama je vjenčanje, rođendan, godišnjica ili drugi veliki
                  dan u životu dragih osoba? Razmišljate o poklonu? Kupite za
                  svoje najbliže pomoć Doule Sanje i darujte im podršku za
                  vrijeme trudnoće! Iznos na bonu odabirete Vi &lt;3
                </p>
              </span>
              <Button bg="#FEF4F7" onClick={() => navigate("/kontakt")}>
                pitaj doulu sanju
              </Button>
            </div>
          </VerticalCard>
        </VerticalCardsContainer>
      </CircleSection>
    </>
  )
}

export default Darujem
