import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { useSpring, animated } from 'react-spring'
import useMedia from 'use-media'

const Container = styled(animated.div)`
  will-change: transform, opacity;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  .paket {
    transform: rotateY(180deg);
    color: #fff;
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #f49dba;

    header {
      font-size: 0.7rem;
      line-height: 1.2rem;
      text-transform: uppercase;
      margin: 30px 0;
      strong {
        font-weight: 500;
        font-size: 1rem;
      }
    }

    span {
      display: inline-block;
      font-weight: 300;
      font-size: 0.7rem;
      color: #ffffff;
      margin-bottom: 15px;
      text-transform: uppercase;
      strong {
        font-weight: 500;
        font-size: 0.8rem;
        text-transform: none;
      }
    }

    .details {
      margin-bottom: 35px;
    }

    em {
      position: absolute;
      bottom: 22px;
      font-style: italic;
      font-weight: 400;
      font-size: 0.7rem;
      color: #fff;
    }
  }

  .workshop {
    transform: rotateY(180deg);
    color: #fff;
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #9c997b;
    display: flex;
    justify-content: center;
    align-items: center;
    header {
      font-size: 20px;
      line-height: 1.4rem;
      text-align: center;
      font-variant: small-caps;
    }
    .address {
      position: absolute;
      top: 222px;
      left: 50%;
      transform: translate(-50%, 0);
      font-weight: 300;
      font-size: 16px;
      width: 320px;
      text-align: center;
    }
    .price {
      position: absolute;
      top: 10px;
      left: 20px;
      width: 320px;
      font-style: normal;
      font-size: 16px;
      line-height: 1.3rem;
    }
    em {
      position: absolute;
      bottom: 10px;
      left: 20px;
      width: 320px;
      font-style: italic;
      font-size: 0.7rem;
      line-height: 1rem;
    }
  }

  @media (max-width: 500px) {
    .paket {
      header {
        margin: 0 0 30px;
      }
    }
    .workshop {
      .address {
        top: 190px;
      }
      .price {
        font-size: 14px;
      }
      em {
        width: 275px;
      }
    }
  }
`

const FlipCard = ({ front, back, frontBgImage, startLeft }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const isSingleRow = useMedia({ maxWidth: 900 })
  const flipDeg = startLeft || isSingleRow ? -180 : 180

  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped ? flipDeg : 0}deg)`,
    config: { mass: 5, tension: 300, friction: 50 },
  })

  const toggleFlip = useCallback(() => setIsFlipped(prev => !prev), [])

  const FrontContent = useMemo(
    () =>
      frontBgImage ? (
        <BackgroundImage
          Tag="div"
          fluid={frontBgImage}
          backgroundColor="#C4C4C4"
          className="flip-image"
        >
          {front}
        </BackgroundImage>
      ) : (
        front
      ),
    [front, frontBgImage]
  )

  return isFlipped ? (
    <Container style={{ opacity, transform }} onClick={toggleFlip}>
      {back}
    </Container>
  ) : (
    <Container
      style={{
        opacity: opacity.interpolate(o => 1 - o),
        transform: transform.interpolate(t => `${t}`),
      }}
      onClick={toggleFlip}
    >
      {FrontContent}
    </Container>
  )
}

export default FlipCard
